import {updateState, withDevtools} from '@angular-architects/ngrx-toolkit';
import {computed} from '@angular/core';
import {
  signalStore,
  withComputed,
  withMethods,
  withState,
  WritableStateSource,
} from '@ngrx/signals';
import {ClientData} from 'src/app/shared/models';
import {KeywordGroup} from '../models/keywords.model';
import {get} from 'http';

type CompanyStateSignal = {
  company: ClientData | null;
  companyKeywordGroup: KeywordGroup | null;
};

const companyInitialState: CompanyStateSignal = {
  company: null,
  companyKeywordGroup: null,
};

export const CompanyStore = signalStore(
  {providedIn: 'root'},
  withDevtools('CompanyStore'),
  withState(companyInitialState),
  withComputed((state) => ({
    getCompany: computed(() => {
      return state.company();
    }),
    getCompanyKeywordGroup: computed(() => {
      return state.companyKeywordGroup();
    }),
  })),
  withMethods((store) => ({
    updateCompany: (payload: Partial<CompanyStateSignal>): void =>
      _updateCompany(store, payload),
  }))
);

function _updateCompany(
  store: WritableStateSource<CompanyStateSignal>,
  payload: Partial<CompanyStateSignal>
): void {
  updateState(
    store,
    '[Company] Update Company',
    (state: CompanyStateSignal) => ({
      ...state,
      ...payload,
    })
  );
}
